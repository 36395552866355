<template>
    <v-container>
        <v-row>
            <v-col cols="8" offset="2">

                <v-card>
                    <v-card-title>{{ $t('faq') }}</v-card-title>

                    <v-card-text>
                        <v-expansion-panels>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Where can I find my “favourite pictures”?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    Go to the header menu ‘my selections’ and click on the folder where the pictures are stored.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    How can I create a folder with my “favourite pictures”?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    Click on add selection, enter a name that you want to use for the folder and click on save, the folder has now been created.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    What does the ID of a picture mean?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    The number of the picture from the picture collection, it is a unique number.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    What does brand mean?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    The brand of the plant, like Sundaville® or Sunpuma®.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    What does the ® trademark mean?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    A symbol, word, or words legally registered or established by use as representing a company or product.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    What does variety mean?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    The name of the plant, it can be combined with a colour.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    What does type mean?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    The name of the collection it belongs to.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    What does cultivar mean?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    The botanical name of the plant.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    What does denomination/ Elite No mean?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    The reference number of the plant (patent).
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    What does shot mean?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    Genre of the picture.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    What does keywords mean?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    The important words that represent the plant.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    What can I do with a remark?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    Here you can write important comments that the administrator receives, other customers will not be able to see the remark.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    What size picture should I download?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    You have the choices between small, medium and large. All pictures are 300 DPI but the ratio is different.<br>
                                    Small: 50%<br>
                                    Medium: 75%<br>
                                    Large: original 100%
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    Are there more pictures available?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    No, these are all the available commercial varieties.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    What does stock picture mean?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    Stock photo can be bought on different websites. These photos can be used for commercial purposes.
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                        </v-expansion-panels>
                    </v-card-text>
                </v-card>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "Faq",

        computed: {
            ...mapState(['isLoggedIn']),
        },

        created() {
            // Route guard
            if (!this.isLoggedIn) {
                this.$store.dispatch('logout')
                this.$router.push('/login')
            }
        },

        data() {
            return {
            }
        },
    }
</script>
